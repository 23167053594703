.homeDiv{
    margin-top: 10vh;
}
.textDiv{
    max-width: 800px;
    margin: 10vh auto 10vh;
}
.textDiv p{
    font-size: 20px;
    text-align: center;
    padding: 10px;
}
.servingphillytext{
    font-size: 60px !important;
}
.bottomDiv{
    background-color: rgb(198, 237, 255);
    height: 50vh;

}
.pagebutton{
    width: 300px;
    font-size: 40px;
    background-color: #000000;
}
.pagebutton a{
    color: #ffffff;
}
.buttonDiv{
    background-color: rgb(255, 255, 255);

}
@media all and (max-width: 800px) {
    .homeDiv{
        margin-top: 0;
    }
    .pagebutton{
        width: 100px;
        height: 50px;
        font-size: 20px;
        background-color: #000000;
        color: #fff;
    }
    .textDiv{
        max-width: 600px;

    }
    .servingphillytext{
        font-size: 30px !important;
    }
    .textDiv{
        max-width: 800px;
        margin: 5vh auto 10vh;
    }
}