body {
  margin: 0;
  height:100%;
  background-color: #ffffff;
  font-family: 'Be Vietnam Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
br {
  content: "";
  margin: 2em;
  display: block;
  font-size: 50%;
}
code {
  font-family: 'Be Vietnam Pro', sans-serif;
}
@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300&display=swap');
