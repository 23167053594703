.hamburger{
    height: 30px;
    justify-content: end;
    margin-right: 15px;
    z-index: 10;
}
.item{
    width: 30px;
    height: 1px;
    margin: 8px;
    border-radius: 10px;
    background-color: black;
}
.item1{
    transform: rotate(45deg) translate(7px, 6px);
    
}
.item2{ 
    opacity: 0;
}
.item3{
    transform:rotate(-45deg) translate(7px, -6px);
}

