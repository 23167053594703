.hamburger{
    height: 30px;
    justify-content: end;
    margin-right: 15px;
    z-index: initial;
}
.item{
    width: 30px;
    height: 1px;
    margin: 7px;
    border-radius: 10px;
    background-color: black;
}


