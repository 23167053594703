.header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  z-index: 100;
} 
 .navigation{
    width: 100%;
    height: 50px;
}
.container {
    position: relative;
    display: flex; 
  }
  .thisbutton {
    padding: 0;
    border: 0;
    margin: 0 10px 0 10px;
    background-color: #fff;
    color: #000000;
    cursor: pointer;
    outline: 0;
    font-size: 20px;
  }
  .thisbutton:hover{
    color: rgb(255, 157, 0);
  }
  .dropdown {
    position: absolute;
    text-decoration: underline #b3b3b3;
    text-underline-offset: 8px;
    text-align: left;
    top: 100%;
    left: 0;
    width: 200px;
    z-index: 2;
    background-color: #fff;
    font-size: 20px;

  }
  .hamburger{
    z-index: 6;
} 
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 999;
  }
  li {
    padding: 8px 12px;
  }
  
  li:hover {
    background-color: rgb(255, 255, 255);
    cursor: pointer;
  }
  a:hover{
    color: rgb(255, 157, 0);
  }
  a{
   text-decoration: none;
   color: #000000;
  }
  .headerdiv{
    display: flex;
    justify-content: space-around;
    background-color: #fff;
  }

  .navigation ul{
    display: flex;
    height: 50px;
    width: 100%;
    margin: 0px;
    padding: 0px;
    overflow: hidden;
    float:10;
    font-size: 20px;
    
}
.navigation ul li{
  list-style-type: none;
  text-align: center;
  margin: auto;    
}
.headerimage{
  width: 300px;
}

  @media all and (max-width: 850px) {
    .hamburger{
      display:flex;
      padding-top: 10px;
      margin-left: 10px;
      z-index: 6;
  }
  .navigation ul{
      background-color: #ffffff;
      height: 100vh;
      margin-top: 50px;
      position: absolute;
      right: 0;
      
  }
  .navigation{
      max-width: 80px;
  }
  .navigation ul li{
      font-size: 25px;
      padding:30px; 
      border-style: none none solid none;   
      border-color: rgb(230, 230, 230);
  }
  .headerimage{
    width: 200px;
    margin: 10px;
  }
  }
 