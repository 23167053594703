.abutdiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
.image{
    margin: auto;
    height: 300px;
}
.textdiv{
    max-width: 600px;
    margin: 10px 30px 30px 30px;
}
.textdiv p{
    font-size: 20px;
    text-align: left;
}
h1{
    margin: 30px;
}
@media all and (max-width: 800px) {
    .image{
        height: 200px;
        }
        .abutdiv{
            margin-top: 30px;
        }
        .textdiv{
            margin-top: 0px;
        }
}