.galleryDiv{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    background-position: bottom;
    background-size: cover;
}
.galleryimage{
    height: 400px;
    width: 400px;
    margin: 10px;
    object-fit: cover;
    border: solid 1px
}

@media screen and (max-width: 500px){
    .galleryimage{
        height: 280px;
        width: 280px;
        
    }
        
}